<template>
    <div class="content-wrapper">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-4">
                        <h1 class="m-0 text-dark">Charge Adjust</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-8">
                        <button class="btn btn-success float-right btn-sm mr-2" @click="bulkModal.show()"><i class="fas fa-upload"></i> Bulk Upload</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="order_id">{{ $t('globalTrans.order_id') }}</label>
                                            <input type="text" id="order_id" class="form-control" placeholder="Order ID" v-model="search.order_id">
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group" style="margin-top:30px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> Filter</button>
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            <slot v-if="loading">
                                                <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                            </slot>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" v-if="form"> 
                                <b-overlay :show="loading">
                                    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(saveForm)" v-on:keyup.enter="saveForm">
                                            <div class="row">                                                
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Tracking ID" vid="tracking_id" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="mobile">Tracking ID<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="mobile" v-model="form.tracking_id" class="form-control" disabled/>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="merchant_id"
                                                                v-model="form.merchant_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.merchantList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="D" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="coverage_area_id">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="coverage_area_id"
                                                                v-model="form.coverage_area_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.coverageAreaList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="D" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="division_id"
                                                                v-model="form.division_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= divisionList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="district_id"
                                                                v-model="form.district_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= districtList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="thana_id"
                                                                v-model="form.thana_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= thanaList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="area_id"
                                                                v-model="form.area_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= areaList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Weight(kg)" vid="weight" v-slot="{errors}">
                                                       <div class="form-group">
                                                            <label for="weight">Weight(kg) </label>
                                                            <v-select name="weight"
                                                                v-model="form.weight"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= commonObj.weightChargeList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Customer Name" disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                        <div class="form-group">
                                                            <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile No" disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="mobile_2">Alternatvie Mobile No (if any)</label>
                                                            <input type="text" id="mobile_2" v-model="form.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" disabled/>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="address" v-model="form.address" class="form-control" placeholder="Address" disabled/>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Amount to Collected" vid="price" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="price">Price <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="price" v-model="form.price" class="form-control" placeholder="Amount to Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Collected" vid="price" v-slot="{errors}" rules="required">
                                                        <div class="form-group">
                                                            <label for="price"> Collected <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="price" v-model="form.collected" class="form-control" placeholder="Amount to Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Service Charge" vid="service_charge" v-slot="{errors}" rules="required|min:0|max:3|max_value:999">
                                                        <div class="form-group">
                                                            <label for="service_charge">Service Charge <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="service_charge" v-model.number="form.service_charge" class="form-control">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Weight Charge" vid="weight_charge" v-slot="{errors}" rules="required|min:0|max:3|max_value:999">
                                                        <div class="form-group">
                                                            <label for="weight_charge">Weight Charge <span class="text-danger" title="Required">*</span></label>
                                                            <input type="text" id="weight_charge" v-model.number="form.weight_charge" class="form-control">
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="ref_id">Reference ID</label>
                                                            <input type="text" id="ref_id" v-model="form.ref_id" class="form-control" placeholder="Reference ID" disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="instruction">Instruction (Note)</label>
                                                            <input type="text" id="instruction" v-model="form.instruction" class="form-control" placeholder="Instruction (Note)" disabled/>
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="exchange"
                                                                v-model="form.exchange"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= exchangeList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                        <div class="form-group">
                                                            <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                            <v-select name="category_id"
                                                                v-model="form.category_id"
                                                                label="text"
                                                                :reduce="item => item.id"
                                                                :options= customState.categoryList
                                                                :placeholder="$t('globalTrans.select')"
                                                                disabled
                                                            />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>                                           
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <ValidationProvider name="Date" vid="date" v-slot="{errors}">
                                                        <div class="form-group">
                                                            <label for="date">Deadline <span class="text-danger" title="Required">*</span></label>
                                                            <input type="date" id="date" v-model="form.date" class="form-control" placeholder="Deadline" disabled />
                                                            <span class="text-danger">{{ errors[0] }}</span>
                                                        </div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <button type="button" class="btn btn-info btn-sm mr-2 mt-30" @click.prevent="chargeAdjust">Submit</button>
                                                        <button type="reset" class="btn btn-danger btn-sm mt-30" @click="reload">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>    
                                        </form>
                                    </ValidationObserver>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'ChargeAdjust',
        data () {
            return {
                bulkModal: 0,
                loading: false,
                search : {
                    order_id: ''
                },
                form: null,
                exchangeList: [
                    { id: 1, text: 'Yes' },
                    { id: 2, text: 'NO' },
                ],
                divisionList: [],
                districtList: [],
                thanaList: [],
                zoneList: [],
                areaList: []
            }
        },
        watch: {
            'form.coverage_area_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.divisionList = this.getDivisionList(newVal)
                    // this.districtList = this.getDistrictByCoverage(newVal)
                }
            },
            'form.division_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.districtList = this.getDistrictList(newVal)
                }
            },
            'form.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'form.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    // this.zoneList = this.getZoneList(newVal)
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form.weight': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.form.weight_charge = this.getWeightCharge(newVal)
                }
            },
        },
        computed : {
            authUser () {
                return this.$store.state.authUser
            },
            customState () {
                return this.$store.state
            },
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods:{
            async searchOrder () {
                this.loading = true
                const response = await config.getData(`/accounts/payment/order/${this.search.order_id}`)
                this.loading = false
                if (response.status == 200) {
                    this.form = response.data  
                } else {
                    this.form = null
                }   
            },
            reload () {
                this.form = null
                this.search = Object.assign({}, {
                    order_id: '',
                    contact: '',
                    reference_id: ''
                })
            },      
            async chargeAdjust(){    
                this.loading = true     
                const params = { id: this.form.id, price: this.form.price, collected: this.form.collected, service_charge: this.form.service_charge, weight_charge: this.form.weight_charge }
                const response = await config.postData('/accounts/payment/charge-adjust', params)
                this.loading = false
                if (response.status == 200){
                    this.$toast.success({
                        title: 'Success',
                        message: 'Charge calculate successfully',
                        color: '#218838'
                    }) 
                }
            },
            getWeightCharge (weight) {
                const tmpCharge = this.commonObj.weightChargeList.find(el => el.id == weight)
                return typeof tmpCharge != 'undefined' ? tmpCharge.charge : 0
            },
            async saveForm () {
                this.loading = true
                const response = await config.postData('order/store', this.form)
                this.loading = false
                if (response.success) {
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })  
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                    this.$refs.form.setErrors(response.errors) 
                }
            },
            getDivisionList (areaCoverageId) {
                this.form.division_id = this.form.thana_id = this.form.district_id = this.form.area_id = ''
                if (areaCoverageId == 1) {
                    this.form.division_id = 3
                    this.getDistrictList(this.form.division_id)
                    return this.commonObj.divisionList.filter(el => el.id == 3)
                }
                return this.commonObj.divisionList
            },
            getDistrictList (divisionId) {
                this.form.district_id = ''
                if (this.form.coverage_area_id == 1) {
                    this.form.district_id = 14
                    return this.commonObj.districtList.filter(el => el.id == 14)
                }
                return this.commonObj.districtList.filter(el => el.division_id == divisionId)
            },
            getThanaList (districtId) {
                return this.commonObj.thanaList.filter(el => (el.district_id == districtId) && (el.coverage_area_id == this.form.coverage_area_id))
            },
            getZoneList (thanaId) {
                return this.commonObj.zoneList.filter(el => el.thana_id == thanaId)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            }
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }
</style>